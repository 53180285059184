import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/LayoutView'
import PageHeader from '../components/common/pageHeader'
import {  BreadcrumbJsonLd, GatsbySeo,  LocalBusinessJsonLd } from 'gatsby-plugin-next-seo'
import Breadcrumb from "../components/PropertyDetails/breadcrumb";

const Contact = ({ data }) => {
  const items = [
    {to:'/', label: 'Home'},
    {label: 'Contact Us'}
  ]
  return (
    <Layout>
      <GatsbySeo
        title='Contact us to get started on owning a property'
        description='Looking for a property in Lagos, Abuja or any other part of Nigeria? Contact us today! '
        openGraph={{
          title: 'Contact us to get started on owning a property',
          description: 'Looking for a property in Lagos, Abuja or any other part of Nigeria? Contact us today!',
          url: 'https://www.example.com/articles/article-title',
          images: 
            {
              url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              width: 850,
              height: 650,
              alt: 'Og Image Alt Article Title A'
            },
          site_name: 'Owning a home does not need to make you lose sleep'
        }}
        twitter={{
          handle: '@ownahome_ng',
          site: '@ownahome.ng',
          cardType: 'summary_large_image'
        }}
      />
      <LocalBusinessJsonLd
      type='Store'
      id='https://ownahome.ng'
      name="Ownahome.ng - Feel at Home"
      description="Land and Houses for sale in Lagos, Abuja and Dubai"
      url='http://www.ownahome.ng'
      telephone='+2348101536132'
      address={{
        streetAddress: '26 Ina Obasi',
        addressLocality: 'Ogudu',
        addressRegion: 'Ikeja',
        postalCode: '100242',
        addressCountry: 'NG',
      }}
      geo={{ 
        latitude: '6.5727272704153314',
        longitude: '3.3873540661371653',
      }}
      images={[
      ]}
    />

<BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Home',
          item: 'https://ownahome.ng',
        },
        {
          position: 2,
          name: 'Contact',
          item: 'https://owanhome.ng/contact',
        },
      ]}
    />  

      <PageHeader heroImage={data.heroImage}>
        <Breadcrumb >
              {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                  {label}
                </Link>
              ))}
            </Breadcrumb>
      </PageHeader>
      <div>
       
        <div className='contact-area pd-top-100 pd-bottom-65'>
          
          <div className='container'>
          <h1 className='h3'>For Enquiries and more Information Contact us Today</h1>
              
            <div className='row'>
              <div className='col-lg-8'>
                <div className='contact-page-map'>
                  <iframe
                    className='w-100'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1981.7993522544955!2d3.3870539580017147!3d6.572223963395989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b928360105bbb%3A0x9dce3acc65a3932a!2s12-28%20Ina%20Obasi%20St%2C%20Ogudu%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sus!4v1627594774018!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy">'
                    style={{ border: 0 }}
                    allowFullScreen
                  />
                </div>
              </div>
              <div className='col-lg-4'>
                <form 
                 name="contact"
                 method="POST"
                 action={`https://formspree.io/f/xleaaepj`}
                 data-netlify="true"
                className='contact-form-wrap contact-form-bg'>
                  <h4>Contact Now</h4>
                  <div className='rld-single-input'>
                    <input type='text' name='name' placeholder='Name' />
                  </div>
                  <div className='rld-single-input'>
                    <input type='email' name='email' placeholder='Email' />
                  </div>
                  <div className='rld-single-input'>
                    <input type='tel' name='tel' placeholder='Phone' />
                  </div>
                  <div className='rld-single-input'>
                    <textarea
                    name='message'
                      rows={10}
                      placeholder='Message'
                      defaultValue={''}
                    />
                  </div>
                  <div className='btn-wrap text-center'>
                    <button type="submit" className='btn btn-yellow'>Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className='row pd-top-92'>
              <div className='col-xl-3 col-sm-6'>
                <div className='single-contact-info'>
                  <p>
                    <i className='fa fa-phone' />
                    Call Us:
                  </p>
                  <h5 className="text-color-green"><a href="tel: +2348101536132" style={{ textDecoration: 'none', color: 'blue' }}>(+234) 8101536132</a></h5>
                </div>
              </div>
              <div className='col-xl-3 col-sm-6'>
                <div className='single-contact-info'>
                  <p>
                    <i className='fa fa-fax' />
                    International:
                  </p>
                  <h5 className="text-color-green"><a href="tel: +1647573-8397" style={{ textDecoration: 'none', color: 'blue' }}>+1 (647) 573-8397</a></h5>
                
                </div>
              </div>
              <div className='col-xl-3 col-sm-6'>
                <div className='single-contact-info'>
                  <p>
                    <i className='fa fa-envelope' />
                    Have any Question?
                  </p>
                  <h5><a href="mailto:emordiuche@ownahome.ng" style={{ textDecoration: 'none', color: 'blue'}}>emordiuche@ownahome.ng</a></h5>
                </div>
              </div>
              <div className='col-xl-3 col-sm-6'>
                <div className='single-contact-info'>
                  <p>
                    <i className='fa fa-phone' />
                    Address
                  </p>
                  <h5>26 Ina Obasi Ogudu,</h5>
                  <h5>Kosofe, Lagos, Nigeria</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    heroImage: contentfulHeroBadge(title: { eq: "contact" }) {
      image {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`

export default Contact
